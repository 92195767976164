import Ajax from './http';

//获取验证码
export function getCodeApi(values) {
	return Ajax.post('/login/send_phone_code', values);
}
//登录
export function loginApi(values) {
	return Ajax.post('/login/phone', values);
}
//邮箱登录
export function loginByEmailApi(values) {
	return Ajax.post('/login/email', values);
}
//修改密码--发送验证码
export function getBackCodeApi(values) {
	return Ajax.post('/login/forget/send_phone_code', values);
}
//修改密码
export function changePwdApi(values) {
	return Ajax.post('/login/forget/update_password', values);
}
